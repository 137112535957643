import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, intervalTime } from '../utils';
import Title from '../components/Title';
import { Snackbar, Alert, SnackbarOrigin, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, TextField, Button, MenuItem, Popover, Typography, Box, Autocomplete } from '@mui/material';
import { groupeList, item, PriceGroupe } from '../types';
import { EditOutlined } from '@mui/icons-material';
import { text } from 'express';
import { titles } from '../theme/styles';
import AutoCompleteItem from '../components/AutoCompleteItem';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function GroupePrice() {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleCloseSnack = () => setOpen(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , client: PriceGroupe, item: item, pricePropre: number | null, priceSale: number | null) => {
    setEditedPrice({ clientID: client.id, itemID: item.id, pricePropre: pricePropre, priceSale: priceSale, groupeName: client.name, item })
    setAnchorEl(event.currentTarget)
  }
  const isPopoverOpen = Boolean(anchorEl)



  const [groupePrice, setGroupePrice] = useState<PriceGroupe[]>()
  const [listGroupe, setListGroupe] = useState<groupeList[]>()
  const [itemList, setItemList] = useState<item[]>([{alwaysShow: true, id: 1, name: "", isWeapon: false, poids: 0, color: ""}])

  const [newPrice, setNewPrice] = useState<{ clientID: number, itemID: number, pricePropre: number | null, priceSale: number | null }>({
    clientID: 1,
    itemID: 1,
    pricePropre: null,
    priceSale: null
  })


  const [editedPrice, setEditedPrice] = useState<{ clientID: number, itemID: number, pricePropre: number | null, priceSale: number | null, groupeName: string, item: item }>({
    clientID: 1,
    itemID: 1,
    pricePropre: null,
    priceSale: null,
    groupeName: "",
    item: {
      alwaysShow: true,
      color: "",
      id: 1,
      name: "",
      poids: 1,
      isWeapon: false,
    }
  })



  useEffect(() => {
    getAllPrice()
    getAllGroupe()
    getItemList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getAllPrice = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/GroupePrice/getAll', {
      mode: 'cors',
      method: "GET",
      credentials: "include"
    })

    const data: PriceGroupe[] = await response.json()

    setGroupePrice(data)
  }

  const getAllGroupe = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/GroupePrice/list', {
      mode: 'cors',
      method: "GET",
      credentials: "include"
    })

    const data: groupeList[] = await response.json()

    setNewPrice(prev => ({ ...prev, clientID: data[0].id }))

    setListGroupe(data)
  }

  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();

      setNewPrice(prev => ({ ...prev, itemID: data[0].id }))

      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const addPrice = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/GroupePrice/price/add', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newPrice)
    })

    setOpen(true)

    getAllPrice()
  }

  const editPrice = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/GroupePrice/price/edit', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(editedPrice)
    })

    setOpen(true)
    handleClose()

    getAllPrice()
  }

  return (
    <>
      <Title text='Prix Groupe' />



      <Paper elevation={16} sx={{ p: 2, display: 'flex', flexDirection: "row", margin: '1rem' }}>
        <TextField sx={{ margin: "1rem" }} id='stockage' select label="Type" variant='outlined' value={newPrice?.clientID} onChange={(e) => { setNewPrice(prev => ({ ...prev, clientID: parseInt(e.target.value) })) }}>

          {
            listGroupe?.map(groupe => {
              return (
                <MenuItem key={groupe.id} value={groupe.id}>
                  {groupe.name}
                </MenuItem>

              )
            })
          }

        </TextField>

      <AutoCompleteItem id={newPrice.itemID} itemList={itemList} onChange={ (v) => setNewPrice(prev => ({ ...prev, itemID: v?.id}))}/>

        <TextField sx={{ margin: "1rem" }} id='mouvement' label="Prix Propre" variant='outlined' error={Number.isNaN(newPrice?.pricePropre)} value={newPrice?.pricePropre}
          onChange={(e) => { setNewPrice(prev => ({ ...prev, pricePropre: parseInt(e.target.value) })) }} />
        <TextField sx={{ margin: "1rem" }} id='mouvement' label="Prix Sale" variant='outlined' error={Number.isNaN(newPrice?.priceSale)} value={newPrice?.priceSale}
          onChange={(e) => { setNewPrice(prev => ({ ...prev, priceSale: parseInt(e.target.value) })) }} />

        <Button onClick={addPrice}>Ajouter</Button>
      </Paper>


      {/* affichage prix  */}
      {
        groupePrice?.map(groupe => {
          if (groupe.GroupePrice.length === 0) return ''

          return (
            <>
              <Title variant='h5' text={groupe.name} />

              <Paper elevation={16} sx={{ p: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ textAlign: "center" }}>Quoi ?</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>Prix Propre</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>Prix Sale</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>Mis a jour</TableCell>
                      <TableCell sx={{ textAlign: "center" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      groupe.GroupePrice?.map(price => {
                        return (
                          <TableRow key={Math.random()}>
                            <TableCell sx={{ textAlign: "center", color: price.item.color }}>{price.item.name}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{price.pricePropre !== null ? formatCurrency(price.pricePropre) : ""}</TableCell>
                            <TableCell sx={{ textAlign: "center" }}>{price.priceSale !== null ? formatCurrency(price.priceSale) : ""}</TableCell>
                            <TableCell>{new Date(price.updatedAt).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })}</TableCell>
                            <TableCell >
                              <Tooltip title="Modifier">
                                <IconButton color="primary" size='small' sx={{ minWidth: 0 }} onClick={(e) => { handleOpen(e, groupe, price.item, price.pricePropre, price.priceSale) }} ><EditOutlined /></IconButton>
                              </Tooltip>
                            </TableCell>

                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </Paper>

            </>
          )


        })}


      {/* PopPop modification prix */}
      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>

          <Box sx={{ p: 2 }}>
            <Typography
              variant={'h5'}
              color="text.primary"
              sx={{ textAlign: "center" }}
            >
              {editedPrice.groupeName}
            </Typography>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography
              variant={'h5'}
              color="text.primary"
              sx={{ textAlign: "center", color: editedPrice.item.color }}
            >
              {editedPrice.item.name}
            </Typography>
          </Box>


          <TextField sx={{ margin: "1rem" }} id='mouvement' label="Prix Propre" variant='outlined' error={Number.isNaN(editedPrice?.pricePropre)} value={editedPrice?.pricePropre}
            onChange={(e) => { setEditedPrice(prev => ({ ...prev, pricePropre: parseInt(e.target.value) })) }} />
          <TextField sx={{ margin: "1rem" }} id='mouvement' label="Prix Sale" variant='outlined' error={Number.isNaN(editedPrice?.priceSale)} value={editedPrice?.priceSale}
            onChange={(e) => { setEditedPrice(prev => ({ ...prev, priceSale: parseInt(e.target.value) })) }} />

          <Button onClick={editPrice}>Valider</Button>
        </Paper>
      </Popover>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleCloseSnack} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default GroupePrice;
