import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime } from '../utils';
import { Snackbar, Alert, SnackbarOrigin, Autocomplete, TextField, Box } from '@mui/material';
import { item } from '../types';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function AutoCompleteItem({ itemList, id, onChange, maxHeight = 400 }: { itemList: item[], id: number, maxHeight?: number, onChange: (v: item) => void }) {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)



  useEffect(() => {
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  return (
    <Autocomplete
      disablePortal
      options={itemList}
      getOptionLabel={Option => Option.name}
      value={itemList.find(item => item.id === id)}
      sx={{ width: 300, margin: "1rem" }}
      renderInput={(params) => <TextField {...params} label="Quoi ?" />}
      ListboxProps={{ style: { maxHeight: maxHeight } }}
      renderOption={(props, option) => {
        const { key, ...optionProps } = props;
        return (
          <Box
            key={key}
            component="li"
            sx={{ color: option.color }}
            {...optionProps}
          >{option.name}</Box>
        )
      }}
      onChange={(e, v) => {
        if (!v) return
        onChange(v)
      }}
    />
  );
}

export default AutoCompleteItem;
