import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CN, User } from '../../types';
import Header from '../../components/header';
import { intervalTime, role } from '../../utils';
import { Paper, TableHead, TableRow, TableCell, TableBody, TextField, MenuItem, Checkbox, Button, Table, FormControlLabel, Box, Typography, Alert, Snackbar } from '@mui/material';

function GestionCN({user}: {user: User | undefined}) {
  let navigate = useNavigate()

  if (!user || user.role !== role.admin) navigate('/')


  const [cnList, setCnList] = useState<CN[]>()

  const [newCN, setnewCN] = useState({
    name: "",
    active: true
  })

  const [open, setOpen] = useState(false)
  const [notifText, setNotifText] = useState("")
  const handleClose = () => setOpen(false)

  useEffect(() => {
    getCNList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  const getCNList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/cn/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: CN[] = await response.json();
      setCnList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const updateName = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setCnList(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.name = e.target.value
        }
        return item
      })
      return list
    })
  }

  const updateActive = (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
    setCnList(tl => {
      const list = tl?.map((item) => {
        if (item.id === id) {
          item.active = e.target.checked
        }
        return item
      })
      return list
    })
  }

  const updateCN = async (id: number) => {
    let item

    if (!cnList) return
    for (let i = 0; i < cnList.length; i++) {
      if (cnList[i].id === id) {
        item = cnList[i]
        break
      }
    }
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/cn/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(item)
    })


    setNotifText("La CN a bien été modifié")
    getCNList()
    setOpen(true)
  }

  const createCN = async () => {

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/cn/create', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newCN)
    })

    setnewCN({
      active: true,
      name: ""
    })

    setNotifText("La CN a bien été creer")
    getCNList()
    setOpen(true)
  }

  return (
    <>

      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>

          Liste CN
        </Typography>
      </Box>

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableBody>
            {cnList?.map(cn => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell >
                    <TextField size='small' id='mouvement' label="Nom" defaultValue={cn.name} variant='outlined' onBlur={(e) => { updateName(e, cn.id) }} />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={cn.active} onChange={(e) => { updateActive(e, cn.id) }}
                        />
                      }
                      label="Activer"
                      labelPlacement='end'
                    />
                  </TableCell>
                  <TableCell>
                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={() => updateCN(cn.id)}>Modifier</Button>

                  </TableCell>
                </TableRow>
              )
            })}

            <TableRow key={Math.random()}>
              <TableCell >
                <TextField size='small' id='mouvement' label="Nom" defaultValue={newCN.name} variant='outlined' onBlur={(e) => { setnewCN(prev => ({ ...prev, name: e.target.value })) }} />
              </TableCell>
              <TableCell>
                <FormControlLabel
                  control={
                    <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newCN.active} onChange={(e) => { setnewCN(prev => ({ ...prev, active: e.target.checked })) }}
                    />
                  }
                  label="Activer"
                  labelPlacement='end'
                />
              </TableCell>
              <TableCell>
                <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={() => createCN()}>Ajouter</Button>

              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert severity="success" variant='filled'>{notifText}</Alert>
      </Snackbar>
    </>
  );
}

export default GestionCN;
