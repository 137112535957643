import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime } from '../../utils';
import Title from '../../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { craftWeapon, item } from '../../types';
import { add, formatDistanceStrict } from 'date-fns';
import { fr } from 'date-fns/locale';
import WeaponTableItem from '../../components/WeaponTableItem';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function FabricationWeapon() {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const [craftList, setCraftList] = useState<craftWeapon[]>()
  const [itemList, setItemList] = useState<item[]>([{ alwaysShow: true, id: 1, name: "", isWeapon: false, poids: 0, color: "" }])


  useEffect(() => {
    getCraftList()
    getItemList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])




  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();


      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const getCraftList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/craft/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: craftWeapon[] = await response.json();

      setCraftList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const craftWeapon = async (id: number) => {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/craft/create', {
        mode: 'cors',
        method: "POST",
        credentials: "include",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({id})
      })
      getCraftList()
      setOpen(true)
  }


  const timeToNextCraft = (craft: craftWeapon) => {
    if (!craft.lastCrafted) return "Maintenant"
    const nextCraft = add(craft.lastCrafted, { hours: craft.cooldown })
    
    if (new Date() <= nextCraft) return formatDistanceStrict( nextCraft, new Date() ,{ locale: fr, addSuffix: true })
    
    return "Maintenant"  
  }

  return (
    <>
      <Title text='Fabrication Armes' />

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Armes</TableCell>
              <TableCell>Recette</TableCell>
              <TableCell>Fabricable :</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {craftList?.map(craft => {
              return <WeaponTableItem craft={craft} itemList={itemList} onClick={() => craftWeapon(craft.id)}/>
            })
          }
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}


export default FabricationWeapon;
