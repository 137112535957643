import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime } from '../../utils';
import Title from '../../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Paper, Box, TextField, Switch, Button, MenuItem } from '@mui/material';
import { client, commande, commandeDetail, priceWeaponCommande } from '../../types';
import AutoCompleteItem from '../../components/AutoCompleteItem';
const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function CommandeEdit() {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const queryParameters = new URLSearchParams(window.location.search)
  const commandeID = queryParameters.get("id")

  const [client, setClient] = useState<client>();

  const [weaponList, setWeaponList] = useState<priceWeaponCommande[]>()

  const [newItem, setNewItem] = useState<number>(0)

  const [commandDetail, setCommandDetail] = useState<commandeDetail[]>([])
  const [commande, setCommande] = useState<commande>({
    id: parseInt(commandeID || "0"),
    clientID: 0,
    isReduction: false,
    delivered: false,
    price: 0,
    priceBase: 0,
    reduction: 0,
    userID: 0,
    createdAt: new Date()

  })



  useEffect(() => {
    initialiseCommand()
    // getCommandeDetail()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  useEffect(() => {
    calculateTotalPrice()
  }, [commandDetail])

  const getCommandeDetail = async () => {
    if (!commandeID) return navigate("/weapon/commande/list")
    if (Number.isNaN(parseInt(commandeID))) return navigate("/weapon/commande/list")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/detail', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ commandeID: parseInt(commandeID) })
      })

      const data: commande = await response.json();
      // return setCommande(data)


      setCommande({
        id: data.id,
        clientID: data.clientID,
        isReduction: data.isReduction,
        delivered: data.delivered,
        price: data.price,
        priceBase: data.priceBase,
        reduction: data.reduction,
        userID: data.userID,
        createdAt: data.createdAt,
      })

      setClient(data.client)
      if (!data.commandeDetail) return
      setCommandDetail(data.commandeDetail)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const initialiseCommand = async () => {
    if (!commandeID) return navigate("/weapon/commande/list")
    if (Number.isNaN(parseInt(commandeID))) return navigate("/weapon/commande/list")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/sell/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const dataWeaponList: priceWeaponCommande[] = await response.json();

      const responseCommand = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/detail', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ commandeID: parseInt(commandeID) })
      })

      const data: commande = await responseCommand.json();

      setCommande({
        id: data.id,
        clientID: data.clientID,
        isReduction: data.isReduction,
        delivered: data.delivered,
        price: data.price,
        priceBase: data.priceBase,
        reduction: data.reduction,
        userID: data.userID,
        createdAt: data.createdAt,
      })

      const commandeDetailData = data.commandeDetail

      setClient(data.client)

      let commandDetailTemp: commandeDetail[] = []
      let tempWeaponList: priceWeaponCommande[] = []

      for (let i = 0; i < dataWeaponList.length; i++) {
        if (dataWeaponList[i].alwaysShow) {

          commandDetailTemp.push({
            quantity: 0,
            itemID: dataWeaponList[i].itemID,
            isReduction: true,
            price: dataWeaponList[i].priceSale,
            reduction: 0,
            commandeID: 0,
            id: 0,
            weapon: dataWeaponList[i]
          })
        } else tempWeaponList.push(dataWeaponList[i])
      }

      setWeaponList(tempWeaponList)
      setNewItem(tempWeaponList[0].itemID)
      
      
      if(!commandeDetailData) return
      
      for (let i = 0; i < commandDetailTemp.length; i++) {
        const commandeItem = commandeDetailData.find(detail => detail.itemID === commandDetailTemp[i].itemID)
        if(!commandeItem) continue
        commandDetailTemp[i].commandeID = commandeItem.commandeID
        commandDetailTemp[i].id = commandeItem.id
        commandDetailTemp[i].price = commandeItem.price
        commandDetailTemp[i].reduction = commandeItem.reduction
        commandDetailTemp[i].quantity = commandeItem.quantity
        
      }
      setCommandDetail(commandDetailTemp)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const calculateBrutePrice = () => {
    let brutePrice = 0
    commandDetail?.forEach(command => {

      if (command.quantity === 0) return
      let cost = command.weapon.priceBuy || 0
      if (command.weapon.craftable) {
        const recipe = command.weapon.recipe?.recipeWeapon
        cost = 0
        if (!recipe) return
        for (let i = 0; i < recipe?.length; i++) {
          const pricePiece = recipe[i].item.priceItemWeapon[0].price
          cost += pricePiece || 0
        }
      }
      brutePrice += cost * command.quantity
    })
    return brutePrice
  }

  const calculateTotalPrice = () => {
    let totalPrice = 0
    commandDetail?.forEach(command => {
      // if(command.quantity === 0) return
      totalPrice += command.price * command.quantity
    })
    setCommande({ ...commande, priceBase: totalPrice, price: totalPrice * (1 - commande.reduction / 100) })
  }

  const changeQuantity = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setCommandDetail(tl => {
      const list = tl.map((command) => {
        if (command.itemID === id) {
          command.quantity = parseInt(e.target.value)
        }
        return command
      })
      return list
    })
    // calculateTotalPrice()
  }

  const addItem = () => {
    let transacItem: priceWeaponCommande
    if (!weaponList) return

    for (let i = 0; i < weaponList.length; i++) {
      if (newItem === weaponList[i].itemID) {
        transacItem = weaponList[i]
        break
      }
    }

    setCommandDetail(prev => [
      ...prev,
      {
        quantity: 0,
        itemID: transacItem.itemID,
        isReduction: true,
        price: transacItem.priceSale,
        reduction: 0,
        commandeID: 0,
        id: 0,
        weapon: transacItem

      }
    ]
    )
    setWeaponList(weaponList?.filter(weapon => {
      if (transacItem.itemID === weapon.itemID) return
      return weapon
    }))

  }


  const addCommande = async () => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/edit', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ commande, commandDetail })
    })

    setOpen(true)
    return navigate("/weapon/commande/list")
  }

  return (
    <>
      <Title text={`Edition Commande d'arme pour ${client?.name} ${client?.type === "PM" ? `(${client.number})` : ""}`} />


      <Box sx={{ p: 2, display: 'flex', flexWrap: "wrap", flexDirection: "row", margin: '1rem', justifyContent: "center" }}>
        {
          commandDetail?.map(commandItem => {
            let cost = commandItem.weapon.priceBuy || 0
            if (commandItem.weapon.craftable) {
              const recipe = commandItem.weapon.recipe?.recipeWeapon
              cost = 0
              if (!recipe) return
              for (let i = 0; i < recipe?.length; i++) {
                const pricePiece = recipe[i].item.priceItemWeapon[0].price
                cost += pricePiece || 0
              }
            }

            return (
              <Paper elevation={16} sx={{ p: 2, margin: "1rem", width: "23%", display: 'flex', flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
                <Box sx={{ width: "100%" }}>
                  <Title text={commandItem.weapon.item.name} variant='h5' />
                </Box>
                {/* <Title text={'Prix d\'achat : ' + cost} variant='h5' /> */}
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label={`Prix ${commandItem.weapon.craftable ? "de fabrication" : "d'achat"}`} variant='outlined' value={cost} />
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label={`Prix Brute`} variant='outlined' value={cost * commandItem.quantity} />

                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Prix de vente" variant='outlined' value={commandItem.weapon.priceSale} />
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' label="Quantité" variant='outlined' value={commandItem.quantity} onChange={(e) => { changeQuantity(e, commandItem.itemID) }} />

                {/* <TextField sx={{ margin: "1rem", width: "30%" }} type='number' disabled={commandItem.isReduction} label="Reduction (%)" variant='outlined' value={commandItem.reduction} onChange={(e) => { changeReduction(e, commandItem.itemID) }} />
                <Switch sx={{ alignSelf: "center" }} value={commandItem.isReduction} onChange={(e) => { changeIsReduction(e, commandItem.itemID) }} ></Switch> */}

                <TextField sx={{ margin: "1rem", width: "50%" }} type='number' disabled label="Prix totale" variant='outlined' value={commandItem.price * commandItem.quantity} />


              </Paper>
            )
          })
        }


        {weaponList?.length !== 0 &&
          <Box sx={{ width: "100%", justifyItems: "center" }}>
            <Paper elevation={16} sx={{ p: 2, margin: "1rem", width: "20%", display: 'flex', flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>


              <TextField id='addItem' select label="Quoi ?" variant='outlined' value={newItem}
                onChange={(e) => { setNewItem(parseInt(e.target.value)) }}
                sx={{ margin: "1rem" }}
                InputProps={{ sx: { color: weaponList?.filter(item => item.id === newItem).map(o => o.item.color) } }}
                SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}>
                {weaponList?.map((option) => (
                  <MenuItem key={option.item.name} value={option.itemID} sx={{ color: option.item.color }}>
                    {option.item.name}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant='outlined' color='success' onClick={addItem}>Ajouter</Button>


            </Paper>
          </Box>
        }


        <Box sx={{ width: "100%", justifyItems: "center" }}>

          <Paper elevation={16} sx={{ p: 2, margin: "1rem", width: "50%", display: 'flex', flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
            <Box sx={{ width: "100%" }}>
              <Title text='Totale' variant='h5' />
            </Box>
            {/* <Title text={'Prix d\'achat : ' + cost} variant='h5' /> */}
            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Coup Brute" variant='outlined' value={calculateBrutePrice()} />
            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Prix base" variant='outlined' value={commande.priceBase} />

            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled={commande.isReduction} label="Reduction (%)" variant='outlined' value={commande.reduction} onChange={(e) => {
              setCommande(prev => ({ ...prev, reduction: parseInt(e.target.value), price: commande.priceBase * (1 - parseInt(e.target.value) / 100) }))

            }} />
            {/* <Switch sx={{ alignSelf: "center" }} value={commande.isReduction} onChange={(e) => { setCommande( prev => ({ ...prev, isReduction: e.target.checked})) }} ></Switch> */}
            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled={!commande.isReduction} label="Prix finale" variant='outlined' value={commande.price} />

            <Button variant='outlined' color='success' onClick={addCommande}>Modifer la commande</Button>


          </Paper>
        </Box>
      </Box>


      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default CommandeEdit;
