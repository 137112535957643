import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatCurrency, getCurrencyColor, intervalTime, role } from "../../utils";
import Title from "../../components/Title";
import { CN, histoCN, User } from "../../types";
import { Snackbar, Alert, SnackbarOrigin, Button, Paper, Table, TableBody, TableCell, TableRow, TextField, Tooltip, Popover, MenuItem, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

const anchorOrigin: SnackbarOrigin = {
  vertical: "bottom",
  horizontal: "right",
};

function EditionMouvementCN({ user }: { user: User | undefined }) {
  let navigate = useNavigate();

  
  if (!user || user.role !== role.admin) navigate("/");
  const [histoList, setHistoList] = useState<histoCN[]>();
  const [cnList, setCnList] = useState<CN[]>([])

  const [ oldMouvement, setOldMouvement] = useState<histoCN>()
  const [ newMouvement, setNewMouvement] = useState<histoCN>(
    {
      amount: 100,
      cn: { name: ""}, 
      cnId: 1,
      commentaire: "",
      createdAt: new Date(),
      id: 1
    }
  )
  //Snackbar
  const [open, setOpen] = useState(false);
  const handleCloseSnack = () => setOpen(false);


  //Popover
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , histoCN: histoCN) => {
    setAnchorEl(event.currentTarget)

    setOldMouvement(histoCN)
    setNewMouvement(histoCN)
  }
  const handleClose = () => setAnchorEl(null);
  const isPopoverOpen = Boolean(anchorEl)

  

  useEffect(() => {
    getHistoCN();
    getCNList()
    const interval = setInterval(() => {}, intervalTime);
    return () => clearInterval(interval);
  }, []);

  const getHistoCN = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/admin/cn/histo",
        {
          mode: "cors",
          method: "GET",
          credentials: "include",
        }
      );

      const data: histoCN[] = await response.json();
      setHistoList(data);
    } catch (error) {
      console.error("Error get user data", error);
    }
  };

  const getCNList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/caisse_noire/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: CN[] = await response.json();

      setCnList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const handleMouvement = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/cn/mouvement/edit', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({oldMouvement, newMouvement})
    })

    handleClose()
    getHistoCN()

    setOpen(true)
    // setTimeout(() => {
    //   setOpen(false)
    // }, 2000)
  }

  
  const handleDelete = async (histo: histoCN) => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/cn/mouvement/delete', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(histo)
    })
    getHistoCN()

    setOpen(true)
    // setTimeout(() => {
    //   setOpen(false)
    // }, 2000)
  }

  return (
    <>
      <Title text="Liste Dernier Mouvement" />

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableBody>
            {histoList?.map(histo => {
              return (
                <TableRow key={Math.random()} >
                  <TableCell  sx={{ textAlign: "center" }}> {new Date(histo.createdAt).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })} </TableCell>
                  <TableCell sx={{ textAlign: "center" }} > {histo.cn.name} </TableCell>
                  <TableCell sx={{ color: getCurrencyColor(histo.amount), fontWeight: "bold" }}>{formatCurrency(histo.amount)}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{histo.commentaire}</TableCell>
                  <TableCell sx={{ textAlign: "center" }} >
                  <Tooltip title="Corriger" disableInteractive><Button startIcon={<EditOutlined />} onClick={e => handleOpen(e, histo)} /></Tooltip>
                  <Tooltip title="Supprimer" disableInteractive><Button startIcon={<DeleteOutlineOutlinedIcon />} onClick={e => handleDelete(histo)} /></Tooltip>
                  </TableCell>
                </TableRow>
              )
            })}
          
          </TableBody>
        </Table>
      </Paper>


      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
            Modifier un mouvement
          </Typography>
          <TextField sx={{ margin: "1rem" }} id='cn' select label="Caisse Noire" variant='outlined' value={newMouvement?.cnId} onChange={(e) => { setNewMouvement(prev => ({ ...prev, cnId: parseInt(e.target.value) })) }}>
            {cnList?.map((option) => (
              <MenuItem key={option.name} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>

          <TextField sx={{ margin: "1rem" }} id='mouvement' label="Quantité" variant='outlined' value={newMouvement.amount} error={Number.isNaN(newMouvement.amount)} onChange={(e) => { setNewMouvement(prev => ({ ...prev, amount: parseInt(e.target.value) })) }} />
          <TextField sx={{ margin: "1rem" }} id='commentaire' label="Commentaire" variant='outlined' value={newMouvement.commentaire} onChange={(e) => { setNewMouvement(prev => ({ ...prev, commentaire: e.target.value })) }} />

          <Button onClick={handleMouvement}>Valider</Button>
        </Paper>
      </Popover>



      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
        anchorOrigin={anchorOrigin}
      >
        <Alert severity="success" variant="filled">
          Le mouvement a été enregistré.
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditionMouvementCN;
