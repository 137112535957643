import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime, role } from '../../utils';
import Title from '../../components/Title';
import { craftWeapon, item, recipeWeapon, User } from '../../types';
import { Snackbar, Alert, SnackbarOrigin, TableCell, FormControlLabel, TextField, TableRow, Checkbox, Button, TableBody, Paper, Table, Autocomplete, Box, Popover, Typography } from '@mui/material';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function GestionWeaponCraft({ user }: { user: User | undefined }) {
  let navigate = useNavigate()

  if (!user || user.role !== role.admin) navigate('/')

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , item: recipeWeapon) => {
      setEditedIngredient(item)
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => setAnchorEl(null);

  const isPopoverOpen = Boolean(anchorEl)

  const [open, setOpen] = useState(false)
  const [itemList, setItemList] = useState<item[]>([{ alwaysShow: true, id: 1, name: "", isWeapon: false, poids: 0, color: "" }])
  const [craftList, setCraftList] = useState<craftWeapon[]>()

  const [newWeaponCraft, setNewWeaponCraft] = useState<{ itemID: number, cooldown: number }>({
    itemID: 1,
    cooldown: 0,
  })

  const [newIngredient, setNewIngredient] = useState<recipeWeapon>({
    id: 0,
    craftWeaponID: 0,
    itemID: 0,
    quantity: 1
  })
  const [editedIngredient, setEditedIngredient] = useState<recipeWeapon>({
    id: 0,
    craftWeaponID: 0,
    itemID: 0,
    quantity: 1
  })


  const handleClose = () => setOpen(false)

  useEffect(() => {
    getItemList()
    getCraftList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();

      setNewWeaponCraft(prev => ({ ...prev, itemID: data[0].id }))

      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getCraftList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/craft/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: craftWeapon[] = await response.json();

      setCraftList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const addCraft = async () => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/craft/create', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newWeaponCraft)
    })

    setOpen(true)
    getCraftList()

  }

  const editIngredient = async () => {

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/craft/ingredient/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(editedIngredient)
    })
    getCraftList()
    setOpen(true)
    handleClosePopover()
  }

  const addIngredient = async (craftId: number) => {

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/craft/ingredient/add', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ newIngredient, craftId })
    })

    getCraftList()
    setNewIngredient({
      craftWeaponID: 0,
      id: 0,
      itemID: 0,
      quantity: 1
    })

    // setNotifText("L'item a bien été ajouté")
    setOpen(true)
  }


  return (
    <>
      <Title text='Recette de fabrication des armes' />



      <Paper elevation={16} sx={{ p: 2, display: 'flex', flexDirection: "row", margin: '1rem' }}>


        <Autocomplete
          disablePortal
          options={itemList}
          getOptionLabel={Option => Option.name}
          sx={{ width: 300, margin: "1rem" }}
          renderInput={(params) => <TextField {...params} label="Quoi ?" />}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ color: option.color }}
                {...optionProps}
              >{option.name}</Box>
            )
          }}
          onChange={(e, v) => {
            if (!v) return
            setNewWeaponCraft(prev => ({ ...prev, itemID: v?.id }))
          }}
        />

        <TextField sx={{ margin: "1rem" }} id='mouvement' label="Timer (en heure)" variant='outlined' error={Number.isNaN(newWeaponCraft?.cooldown)} value={newWeaponCraft?.cooldown}
          onChange={(e) => { setNewWeaponCraft(prev => ({ ...prev, cooldown: parseInt(e.target.value) })) }} />

        <Button onClick={addCraft}>Ajouter</Button>
      </Paper>


      {craftList?.map(craft => {
        return (
          <>
            <Title text={`${itemList.find(item => item.id === craft.itemID)?.name} (${craft.cooldown}h)`} />
            <Paper elevation={16} sx={{ p: 2 }}>
              <Table>
                {/* <TableRow>
                  <TableCell>Timer (en heure) :</TableCell>
                  <TableCell>{`${craft.cooldown}h`}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} >Modifier</Button>
                  </TableCell>
                </TableRow> */}

                <TableBody>
                  <TableRow>
                    <TableCell>Recette :</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell ></TableCell>
                  </TableRow>
                  {craft.recipeWeapon?.map(craftIngredient => {
                    return (
                      <TableRow key={Math.random()} >
                        <TableCell>{
                          itemList.find(item => item.id === craftIngredient.itemID)?.name
                        }</TableCell>
                        <TableCell>Quantité : {craftIngredient.quantity}</TableCell>
                        <TableCell />

                        <TableCell sx={{ textAlign: "center" }} >

                          <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={(e) => handleOpen(e, craftIngredient)}>Modifier</Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                  <TableRow key={Math.random()} >
                    <TableCell >
                      <Autocomplete
                        disablePortal
                        options={itemList}
                        getOptionLabel={Option => Option.name}
                        value={itemList.find(item => item.id === newIngredient.itemID)}
                        sx={{ width: 300, margin: "1rem" }}
                        renderInput={(params) => <TextField {...params} label="Quoi ?" />}
                        renderOption={(props, option) => {
                          const { key, ...optionProps } = props;
                          return (
                            <Box
                              key={key}
                              component="li"
                              sx={{ color: option.color }}
                              {...optionProps}
                            >{option.name}</Box>
                          )
                        }}
                        onChange={(e, v) => {
                          if (!v) return
                          setNewIngredient(prev => ({ ...prev, itemID: v?.id }))
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ textAlign: "center" }} >
                      <TextField size='small' id='mouvement' label="Quantité" defaultValue={newIngredient.quantity} variant='outlined' onBlur={(e) => setNewIngredient(prev => ({ ...prev, quantity: parseInt(e.target.value) }))} />
                    </TableCell>
                    <TableCell />
                    <TableCell sx={{ textAlign: "center" }} >
                      <Button color='success' variant='contained' sx={{ textAlign: "center" }} onClick={() => addIngredient(craft.id)}>Ajouter</Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </>
        )
      })}

      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)' ,
        }}
        open={isPopoverOpen}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Title text='Modifer un ingredient' variant='h6' />


          {/* <TextField sx={{ margin: "1rem" }} size='small' id='mouvement' label="Nom" value={editedIngredient.itemID} variant='outlined' onChange={(e) => setEditedIngredient(prev => ({ ...prev, itemID: e.target.value }))} /> */}
          <Autocomplete
            disablePortal
            options={itemList}
            getOptionLabel={Option => Option.name}
            value={itemList.find(item => item.id === editedIngredient.itemID)}
            sx={{ width: 300, margin: "1rem" }}
            renderInput={(params) => <TextField {...params} label="Quoi ?" />}
            ListboxProps={{style:{ maxHeight: 130}}}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component="li"
                  sx={{ color: option.color}}
                  {...optionProps}
                >{option.name}</Box>
              )
            }}
            onChange={(e, v) => {
              if (!v) return
              setEditedIngredient(prev => ({ ...prev, itemID: v?.id }))
            }}
          />

          <TextField sx={{ margin: "1rem" }} size='small' id='mouvement' label="Quantité" value={editedIngredient.quantity} variant='outlined' onChange={(e) => setEditedIngredient(prev => ({ ...prev, quantity: parseInt(e.target.value) }))} />



          <Button onClick={editIngredient}>Valider</Button>
        </Paper>
      </Popover>


      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default GestionWeaponCraft;
