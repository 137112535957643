import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, getCurrencyColor, intervalTime, role } from '../../utils';
import Title from '../../components/Title';
import { item, priceWeapon, prixPiece, User } from '../../types';
import { Snackbar, Alert, SnackbarOrigin, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableRow, TextField, Popover, TableHead } from '@mui/material';
import AutoCompleteItem from '../../components/AutoCompleteItem';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function GestionWeaponPrice({ user }: { user: User | undefined }) {
  let navigate = useNavigate()

  if (!user || user.role !== role.admin) navigate('/')

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , item: priceWeapon) => {
    setEditedPrice(item)
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => setAnchorEl(null);
  const isPopoverOpen = Boolean(anchorEl)

  const [itemList, setItemList] = useState<item[]>([{ alwaysShow: true, id: 1, name: "", isWeapon: false, poids: 0, color: "" }])
  const [priceList, setPriceList] = useState<priceWeapon[]>()

  const [editedPrice, setEditedPrice] = useState<priceWeapon>({
    craftable: false,
    craftWeaponID: 0,
    id: 0,
    itemID: 0,
    price: 0,
    priceBuy: 0,
    priceSale: 0,
    alwaysShow: false
  })
  const [newPrice, setNewPrice] = useState<priceWeapon>({
    craftable: false,
    craftWeaponID: 0,
    id: 0,
    itemID: 0,
    price: 0,
    priceBuy: 0,
    priceSale: 0,
    alwaysShow: false
  })

  const handleClose = () => setOpen(false)

  useEffect(() => {
    getPriceList()
    getItemList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();
      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }
  const getPriceList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/price/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: priceWeapon[] = await response.json();

      setPriceList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const editPrice = async () => {

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/price/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(editedPrice)
    })

    getPriceList()
    setOpen(true)
    handleClosePopover()
  }

  const addPrice = async () => {

    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/price/new', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newPrice)
    })

    getPriceList()
  }

  return (
    <>
      <Title text='' />

      <Title text="Prix des piece d'armes" />

      <Paper elevation={16} sx={{ p: 2, display: 'flex', flexDirection: "row", margin: '1rem' }}>
        <AutoCompleteItem id={editedPrice.itemID} itemList={itemList} onChange={(v) => setNewPrice(prev => ({ ...prev, itemID: v?.id }))} />
        <TextField sx={{ margin: "1rem" }} type='number' id='price' label="Prix Vente" value={newPrice.priceSale} variant='outlined' onChange={(e) => setNewPrice(prev => ({ ...prev, priceSale: Number(e.target.value) }))} />
        <TextField sx={{ margin: "1rem" }} type='number' id='price' label="Prix Achat" value={newPrice.priceBuy} variant='outlined' onChange={(e) => setNewPrice(prev => ({ ...prev, priceBuy: Number(e.target.value) }))} />
          
        <FormControlLabel 
        sx={{alignSelf: "center"}}
            control={
              <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newPrice.craftable} onChange={(e) => setNewPrice(prev => ({ ...prev, craftable: e.target.checked }))}
              />
            }
            label="Craftable"
            labelPlacement='bottom'
          />
           <FormControlLabel 
           sx={{alignSelf: "center"}}
            control={
              <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={newPrice.alwaysShow} onChange={(e) => setNewPrice(prev => ({ ...prev, alwaysShow: e.target.checked }))}
              />
            }
            label="Toujours Montré"
            labelPlacement='bottom'
          />
        <Button onClick={addPrice}>Ajouter</Button>
      </Paper>

      <br />

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Prix Vente</TableCell>
                <TableCell>Prix Achat</TableCell>
                <TableCell></TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

            {priceList?.map(price => {
              const item = itemList?.find(item => item.id === price.itemID)
              if (!item) return
              return (
                <TableRow key={Math.random()} >
                  <TableCell sx={{ textAlign: "center", color: item.color }}>
                    {item.name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: getCurrencyColor(price.price) }}>
                    {formatCurrency(price.priceSale)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: getCurrencyColor(price.price) }}>
                    {price.priceBuy ? formatCurrency(price.priceBuy) : ""}
                  </TableCell>

                  <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={price.craftable}
                        />
                      }
                      label="Fabricable"
                      labelPlacement='end'
                    />

                  </TableCell>
                  <TableCell sx={{ textAlign: "center", alignItems: "center", justifyContent: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={price.alwaysShow}
                        />
                      }
                      label="Toujours Montré"
                      labelPlacement='end'
                    />
                    
                  </TableCell>
                  

                  <TableCell sx={{ textAlign: "center" }} >

                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={(e) => handleOpen(e, price)}>Modifier</Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>


      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Title text='Modifier Une Piece' variant='h6' />


          <AutoCompleteItem id={editedPrice.itemID} itemList={itemList} onChange={(v) => setEditedPrice(prev => ({ ...prev, id: v.id }))} maxHeight={140} />

          <TextField sx={{ margin: "1rem" }} size='small' type='number' id='mouvement' label="Prix vente" value={editedPrice.priceSale} variant='outlined' onChange={(e) => setEditedPrice(prev => ({ ...prev, priceSale: parseInt(e.target.value) }))} />
          <TextField sx={{ margin: "1rem" }} size='small' type='number' id='mouvement' label="Prix Achat" value={editedPrice.priceBuy} variant='outlined' onChange={(e) => setEditedPrice(prev => ({ ...prev, priceBuy: parseInt(e.target.value) }))} />

          <FormControlLabel 
            control={
              <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={editedPrice.craftable} onChange={(e) => setEditedPrice(prev => ({ ...prev, craftable: e.target.checked }))}
              />
            }
            label="Craftable"
            labelPlacement='bottom'
          />
                     <FormControlLabel 
           sx={{alignSelf: "center"}}
            control={
              <Checkbox size='small' sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} checked={editedPrice.alwaysShow} onChange={(e) => setEditedPrice(prev => ({ ...prev, alwaysShow: e.target.checked }))}
              />
            }
            label="Toujours Montré"
            labelPlacement='bottom'
          />

          <Button onClick={editPrice}>Valider</Button>
        </Paper>
      </Popover>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default GestionWeaponPrice;
