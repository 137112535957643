import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { titles } from "../theme/styles";
import { title } from "process";

function Title({ text, variant = "h4", sxProp }: { text: string; variant?: Variant; sxProp?: SxProps<Theme> }) {

  return (
    <Box sx={{ p: 2 }}>
      <Typography
        variant={variant}
        color="text.primary"
        sx={titles}
      >
        {text}
      </Typography>
    </Box>
  );
}

export default Title;
