import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatCurrency, getCurrencyColor, intervalTime, role } from '../../utils';
import Title from '../../components/Title';
import { item, prixPiece, User } from '../../types';
import { Snackbar, Alert, SnackbarOrigin, Button, Checkbox, FormControlLabel, Paper, Table, TableBody, TableCell, TableRow, TextField, Popover } from '@mui/material';
import AutoCompleteItem from '../../components/AutoCompleteItem';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function GestionPiecePrice({ user }: { user: User | undefined }) {
  let navigate = useNavigate()

  if (!user || user.role !== role.admin) navigate('/')

  const [open, setOpen] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    , item: prixPiece) => {
    setEditedPiece(item)
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => setAnchorEl(null);
  const isPopoverOpen = Boolean(anchorEl)

  const [itemList, setItemList] = useState<item[]>([{ alwaysShow: true, id: 1, name: "", isWeapon: false, poids: 0, color: "" }])
  const [pieceList, setPieceList] = useState<prixPiece[]>()

  const [editedPiece, setEditedPiece] = useState<prixPiece>({
    id: 0,
    itemID: 0,
    price: 0
  })
  const [newPiece, setNewPiece] = useState<prixPiece>({
    id: 0,
    itemID: 0,
    price: 0
  })

  const handleClose = () => setOpen(false)

  useEffect(() => {
    getPriceList()
    getItemList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/stock/itemList', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: item[] = await response.json();
      setItemList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }
  const getPriceList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/piece/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: prixPiece[] = await response.json();

      setPieceList(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const editPiece = async () => {
    // let item

    // if (!itemList) return
    // for (let i = 0; i < itemList.length; i++) {
    //   if (itemList[i].id === id) {
    //     item = itemList[i]
    //     break
    //   }
    // }
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/piecePrice/update', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(editedPiece)
    })

    getPriceList()
    setOpen(true)
    handleClosePopover()
  }

  const addPiece = async () => {
    // let item

    // if (!itemList) return
    // for (let i = 0; i < itemList.length; i++) {
    //   if (itemList[i].id === id) {
    //     item = itemList[i]
    //     break
    //   }
    // }
    await fetch(process.env.REACT_APP_BACKEND_URL + '/admin/weapon/piecePrice/new', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify(newPiece)
    })

    getPriceList()
  }

  return (
    <>
      <Title text="Prix des piece d'armes" />

      <Paper elevation={16} sx={{ p: 2,display: 'flex', flexDirection: "row", margin: '1rem' }}>
        <AutoCompleteItem id={editedPiece.itemID} itemList={itemList} onChange={(v) => setNewPiece(prev => ({ ...prev, itemID: v?.id }))} />
          <TextField sx={{ margin: "1rem" }} type='number' id='price' label="Prix" value={newPiece.price} variant='outlined' onChange={(e) => setNewPiece(prev => ({...prev, price: Number(e.target.value) }))} />
            <Button onClick={addPiece}>Ajouter</Button>
      </Paper>

      <br />

      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableBody>
            {pieceList?.map(piece => {
              const item = itemList?.find(item => item.id === piece.itemID)
              if (!item) return
              return (
                <TableRow key={Math.random()} >
                  <TableCell sx={{ textAlign: "center", color: item.color }}>
                    {item.name}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center", color: getCurrencyColor(piece.price) }}>
                    {formatCurrency(piece.price)}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }} >

                    <Button color='warning' variant='contained' sx={{ textAlign: "center" }} onClick={(e) => handleOpen(e, piece)}>Modifier</Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>


      <Popover
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: 'color-mix(in oklab, color-mix(in oklab, #282c34, black 50%), transparent 20%)',
          backdropFilter: 'blur(5px)'
        }}
        open={isPopoverOpen}
        onClose={handleClosePopover}
        anchorEl={anchorEl}
        anchorReference='none'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Paper elevation={16} sx={{ display: 'flex', flexDirection: "column", p: 2 }}>
          <Title text='Modifier Une Piece' variant='h6' />


          <AutoCompleteItem id={editedPiece.itemID} itemList={itemList} onChange={(v) => setEditedPiece(prev => ({ ...prev, id: v.id }))} maxHeight={140} />

          <TextField sx={{ margin: "1rem" }} size='small' id='mouvement' label="Prix" value={editedPiece.price} variant='outlined' onChange={(e) => setEditedPiece(prev => ({ ...prev, price: parseInt(e.target.value) }))} />

          <Button onClick={editPiece}>Valider</Button>
        </Paper>
      </Popover>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default GestionPiecePrice;
