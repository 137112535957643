import React, { useEffect, useState } from 'react';
import './css/home.css';
import { useNavigate } from 'react-router-dom';
import { User } from '../types';
import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Header from '../components/header';
import { intervalTime } from '../utils';

const stateList = ["Villa", "Gestion PM", "Cayo", "Braquage/Deal", "Caisse"]

function App({user}: {user: User | undefined}) {

  const [listUser, setListUser] = useState<User[]>([])

  const [ userState, setUserState] = useState(user?.etat)


  // console.log(process.env.NODE_ENV)

  useEffect(() => {
    getUserList()
    const interval = setInterval(() => {
      getUserList()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getUserList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/user/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      // setList([...list, 100])
      const data: User[] = await response.json();

      for (let i = 0; i < data.length; i++) {
        if(data[i].id === user?.id) setUserState(data[i].etat)
        
      }

      setListUser(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const connectUser = async (state: string) => {
    await fetch(process.env.REACT_APP_BACKEND_URL + '/user/connect', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ state: state })
    })

    getUserList()
  }

  const disconnectUser = async (userId: number | undefined) => {
    if (userId === undefined) return
    await fetch(process.env.REACT_APP_BACKEND_URL + '/user/disconnect', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ id: userId })
    })

    getUserList()
  }


  return (
    <>
      {/* <div className='container'> */}
      <Box sx={{ display: 'flex', gap: 1, margin: "2rem" }}>
        {stateList.map(state => {
          return (
            <Button variant='contained' color='success' onClick={() => connectUser(state)}>
              {state}
            </Button>
          )
        })}
        {userState !== 'stop' && (<Button variant='contained' color='error' onClick={() => disconnectUser(user?.id)}>
          Stop
        </Button>)}

      </Box>
      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Membre</TableCell>
              <TableCell>Connexion</TableCell>
              <TableCell>Deconnexion</TableCell>
              <TableCell>Etat</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listUser?.map(user => {
              if (user.connected === null) return
              return (
                <TableRow key={Math.random()}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{new Date(user.connected).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })}</TableCell>
                  <TableCell>{(user.etat === "stop" && (new Date(user.disconnected).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' }))) || "En cours"}</TableCell>
                  <TableCell>{user.etat === 'stop' ? '' : user.etat}</TableCell>
                  <TableCell>
                    {user.etat !== 'stop' && (
                      <Button variant='outlined' color='error' onClick={() => disconnectUser(user.id)}>
                        Arreter
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>
      {/* </div> */}
    </>
  );
}

export default App;
