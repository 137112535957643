import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency, intervalTime } from '../../utils';
import Title from '../../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { commande } from '../../types';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function CommandeDetail() {
  let navigate = useNavigate()
  
  const [open, setOpen] = useState(false)
  
  const handleClose = () => setOpen(false)

  const queryParameters = new URLSearchParams(window.location.search)
  const commandeID = queryParameters.get("id")

  const [ commandeDetail, setCommandeDetail ] = useState<commande>({
    clientID: 0,
    createdAt: new Date(),
    id: 0,
    isReduction: false,
    delivered: true,
    price: 0,
    priceBase: 0,
    reduction: 0,
    userID: 0,
  })



  useEffect(() => {
    getCommandeDetail()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getCommandeDetail = async () => {
    if (!commandeID) return navigate("/weapon/commande/list")
      if (Number.isNaN(parseInt(commandeID))) return navigate("/weapon/commande/list")
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/detail', {
          headers: {
            "Content-Type": "application/json",
          },
          mode: 'cors',
          method: "POST",
          credentials: "include",
          body: JSON.stringify({ commandeID: parseInt(commandeID) })
        })
  
        const data: commande = await response.json();
        return setCommandeDetail(data)
  
      } catch (error) {
        console.error('Error get user data', error);
      }
  }

  const validateCommande = async () => {
    if(!commandeDetail) return
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/validate', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ commandeID: commandeDetail.id })
      })

      navigate("/weapon/commande/histo")
  }

  return (
    <>
        <Title text={`Commande pour ${commandeDetail?.client?.name}`} />


        <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}>Quoi ?</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Quantité</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Prix</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commandeDetail?.commandeDetail?.map(commande => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell sx={{ textAlign: "center" }}>{commande.item?.name}</TableCell>
                  {/* <TableCell sx={{ textAlign: "center" }}>{commande.reduction}%</TableCell> */}
                  <TableCell sx={{ textAlign: "center" }}>{commande.quantity}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{formatCurrency(commande.price * commande.quantity)}</TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>

      <Paper elevation={16} sx={{ p: 2, margin: "1rem" }}>
            <TextField sx={{ margin: "1rem", width: "30%" }} type='number' disabled label="Prix base" variant='outlined' value={commandeDetail.priceBase} />

            <TextField sx={{ margin: "1rem", width: "30%" }} type='number' disabled label="Reduction (%)" variant='outlined' value={commandeDetail.reduction} />
            {/* <Switch sx={{ alignSelf: "center" }} value={commande.isReduction} onChange={(e) => { setCommande( prev => ({ ...prev, isReduction: e.target.checked})) }} ></Switch> */}
            <TextField sx={{ margin: "1rem", width: "30%" }} type='number' disabled label="Prix finale" variant='outlined' value={commandeDetail.price} />

            {!commandeDetail.delivered && <Button variant='outlined' color='success' onClick={validateCommande}>Valider la livraison de la commande</Button>}
            <br/>
            {!commandeDetail.delivered && <Button variant='outlined' color='success' component={Link} to={`/weapon/commande/edit?id=${commandeID}`}>Modifier la commande</Button>}
      </Paper>
    
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default CommandeDetail;
